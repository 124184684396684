<template>
  <div class="learning-page">
    <div class="content-wrap">
      <div class="learning-blank">
        <div class="title">我的课程</div>
        <div class="type-blank">
          <div class="type-content">
            <div class="left">全部科目：</div>
            <div class="type-list">
              <div class="list-item" :class="{'list-item-selected' : curCategory === ''}" @click="tapCategory('')">全部</div>
              <div class="list-item" :class="{'list-item-selected' : curCategory === val.id }" v-for="val in categoryList" :key="val.id" @click="tapCategory(val.id)">{{ val.name }}</div>
            </div>
          </div>
          <div class="type-content">
            <div class="left">全部班级：</div>
            <div class="type-list">
              <div class="list-item" :class="{'list-item-selected' : curPackageId === ''}" @click="checkPackage('')">全部</div>
              <div class="list-item" :class="{'list-item-selected' : curPackageId === val.package.id}" v-for="(val, index) in packageList" :key="index" @click="checkPackage(val.package.id)">{{ val.package.name }}</div>
            </div>
          </div>
        </div>
        <div v-if="!loading">
          <div v-if="courseData.length > 0" class="my-course-blank">
            <!--  下架失效-->
            <div class="course-item" :class="{'course-item-expired' : item.course1.is_onoff !== 1}" v-for="(item, index) in courseData" :key="index" @click="toNavCourseInfo(item)">
              <div class="cover-wrap">
                <img v-if="item.course1.pc_cover1" class="cover" :src="item.course1.pc_cover1" />
                <div v-else class="cover">
                  <default-cover />
                </div>
                <div class="expired-wrap">
                  <!--下架-->
                  <img v-if="item.course1.is_onoff !== 1" src="../assets/img/sold_out_icon.png" />
                  <!--失效-->
                  <!--<img src="../assets/img/sold_out_icon.png" />-->
                </div>
              </div>
              <div class="info-wrap">
                <span class="name">{{ item.course1.name }}</span>
                <div class="process-blank">
                  <div class="process-line">
                    <div class="line-cur" :style="{'width' : item.course1.speed + '%'}"></div>
                  </div>
                  <div class="process-info">{{ item.course1.num }}/{{ item.course1.sum }}</div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="course-no-data">
            <img src="../assets/img/course_no_data.png" />
            <span>您还没有报名课程，快去选课吧~</span>
            <div @click="toNavCourseSelection()">去选课</div>
          </div>
        </div>
        <div v-if="loading"  style="padding: 200px 0; background-color: #fff;">
          <loading :is-load="loading" />
        </div>
      </div>
      <div class="learning-blank live-blank">
        <div class="title">我的直播</div>
        <div v-if="liveData.length > 0" class="list-wrap">
          <div class="live-item" v-for="(item, index) in liveData" :key="index" @click="toNavCourseInfo(item)">
            <div class="title-wrap">
              <span v-if="item.course1.is_free === 1" class="free-state">免费</span>
              <span v-if="item.course1.is_free === 2" class="free-state" style="background-color: #7438ef;">密码</span>
              <span class="live-title">{{ item.course1.name }}</span>
            </div>
            <span class="live-date">直播时间：{{ item.course1.live.start_time | getYMDHMS('yyyy-MM-dd hh:mm') }}</span>
            <div class="live-info">
              <img v-if="item.course1.teachers && item.course1.teachers[0] && item.course1.teachers[0].avatar" class="live-teacher" :src="item.course1.teachers[0].avatar" />
              <img v-else class="live-teacher" src="../assets/img/avtar_icon.jpg" />
              <div v-if="item.course1.live.status === 0" class="live-state live-state-before">
                <img src="../assets/img/live_before_icon.png" />
                <span>未开始</span>
              </div>
              <div v-if="item.course1.live.status === 1" class="live-state live-state-start">
                <img src="../assets/img/live_start_icon.png" />
                <span>直播中</span>
              </div>
              <div v-if="item.course1.live.status === 3" class="live-state live-state-end">
                <img src="../assets/img/live_end_icon.png" />
                <span>已结束</span>
              </div>
              <div class="right">
                <span v-if="item.course1.is_free !== 1&&item.course1.is_free !== 2" class="price">￥{{ item.course1.price }}</span>
                <span class="count">{{ item.course1.virtually_pay_num !== '0' && item.course1.virtually_pay_num  !== '' ? item.course1.virtually_pay_num : item.course1.user_count }}人已报名</span>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="live-no-data">
          <img src="../assets/img/live_no_data.png">
          <span>暂时还没有内容~</span>
        </div>
      </div>
    </div>
    <loading :is-load="loading" />
  </div>
</template>

<script>
import * as courseService from '@/service/course-service';

export default {
  name: 'myLearning',
  data() {
    return {
      loading: false,
      typeIndex: 0,
      categoryList: [], // 课程科目
      curCategory: '', // 当前科目
      packageList: [], // 我的套课
      curPackageId: '', // 当前套课id
      courseData: [], // 我的课程
      liveData: [] // 我的直播
    };
  },
  created() {
    this.getMyPackage();
    this.getCategoryList();
    this.getMyCourseList();
    this.getMyLiveList();
  },
  methods: {
    /**
     * 获取课程科目
     * */
    getCategoryList() {
      courseService.getCategoryList().then(res => {
        if (res.code === 1) {
          this.categoryList = res.data;
        }
      });
    },
    /**
     * 查询我的套课getMyPackage
     * */
    getMyPackage() {
      courseService.getMyPackage().then(res => {
        if (res.code === 1) {
          this.packageList = res.data;
        }
      });
    },
    /**
     * 查询我的课程
     * */
    getMyCourseList() {
      const data = {
        category_id: this.curCategory,
        package_id: this.curPackageId,
        course_type: '1'
      };
      this.loading = true;
      courseService.getMyCourse(data).then(res => {
        this.loading = false;
        if (res.code === 1) {
          this.courseData = res.data || [];
          this.courseData.map((item, index) => {
            if (item.course1.is_onoff !== 1) {
              this.courseData.splice(index, 1);
            }
          });
        }
      });
    },
    /**
     * 查询我的直播
     * */
    getMyLiveList() {
      const data = {
        course_type: '2'
      };
      courseService.getMyCourse(data).then(res => {
        if (res.code === 1) {
          this.liveData = res.data || [];
        }
      });
    },
    /**
     * 切换科目
     * */
    tapCategory(id) {
      this.curCategory = id;
      this.getMyCourseList();
    },
    checkPackage(id) {
      this.curPackageId = id;
      this.getMyCourseList();
    },
    /**
     * 去选课
     * */
    toNavCourseSelection() {
      this.$router.push({
        path: '/courseSelection'
      });
    },
    /**
     * 课程详情
     * */
    toNavCourseInfo(info) {
      this.$router.push({
        path: info.course1.course_type === '0' ? '/packageDetail' : '/courseDetail',
        query: {
          id: info.course1.id
        }
      });
    },
    /**
     * 根据时间直播状态
     * */
    formatterState(startDate, endDate) {
      const curTime = new Date().getTime();
      const startTime = new Date(startDate).getTime();
      const endTime = new Date(endDate).getTime();
      // 开始时间大于当前时间，未开始
      if (startTime > curTime) {
        return 1;
      }
      // 当前时间大于开始时间并小于结束时间，直播中
      if (curTime > startTime && new Date().toDateString() === new Date(startDate).toDateString()) {
        return 2;
      }
      // 已结束
      return 3;
    }
  }
};
</script>

<style lang="scss" scoped>
.learning-page {
    .content-wrap {
      display: flex;
      flex-direction: column;
      padding: 25px 0 50px;
      .learning-blank {
        background: #FFFFFF;
        border-radius: 6px;
        padding: 25px 20px 28px;
        margin-bottom: 20px;
        .title {
          font-size: 18px;
          font-weight: bold;
          color: #000022;
          padding-bottom: 15px;
          border-bottom: 1px solid #F6F6F6;
        }
        .type-blank {
          display: flex;
          flex-direction: column;
          background: #FFFFFF;
          border-radius: 8px;
          padding: 15px 0;
          border-bottom: 1px solid #F6F6F6;
          .type-content {
            display: flex;
            align-items: flex-start;
            margin-bottom: 20px;
          }
          .type-content+.type-content {
            margin-bottom: 0;
          }
          .left {
            font-size: 16px;
            color: #333333;
            margin-right: 33px;
            line-height: 28px;
          }
          .type-list {
            flex: 1;
            display: flex;
            flex-wrap: wrap;
            .list-item {
              display: flex;
              align-items: center;
              height: 28px;
              padding: 0 10px;
              font-size: 14px;
              font-weight: 400;
              color: #666666;
              cursor: pointer;
            }
            .list-item-selected {
              background: #2869F5;
              border-radius: 2px;
              color: #FFFFFF;
            }
          }
        }
        .my-course-blank {
          display: flex;
          flex-wrap: wrap;
          margin-top: 4px;
          .course-item {
            display: flex;
            align-items: center;
            width: 380px;
            height: 110px;
            background: #F8F9FB;
            border-radius: 6px;
            padding: 17px 10px 17px 15px;
            margin-top: 16px;
            margin-right: 10px;
            cursor: pointer;
            .cover-wrap {
              position: relative;
              width: 120px;
              height: 74px;
              border-radius: 6px;
              margin-right: 10px;
              overflow: hidden;
              .cover {
                width: 100%;
                height: 100%;
              }
              .expired-wrap {
                position: absolute;
                left: 30px;
                top: 8px;
                img {
                  width: 60px;
                  height: 60px;
                }
              }
            }
            .info-wrap {
              display: flex;
              flex-direction: column;
              flex: 1;
              overflow: hidden;
              line-height: 1;
              .name {
                line-height: 1;
                font-size: 16px;
                color: #333333;
                margin-bottom: 35px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
              .process-blank {
                display: flex;
                align-items: center;
                justify-content: space-between;
                .process-line {
                  position: relative;
                  flex: 1;
                  height: 10px;
                  background: #FFFFFF;
                  border-radius: 5px;
                  overflow: hidden;
                  .line-cur {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 79%;
                    height: 100%;
                    background: #2669F5;
                    border-radius: 5px;
                  }
                }
                .process-info {
                  font-size: 16px;
                  color: #666666;
                  margin-left: 12px;
                }
              }
            }
          }
          .course-item:nth-child(3n) {
            margin-right: 0;
          }
          .course-item-expired {
            .info-wrap {
              opacity: 0.5;
            }
          }
        }
        .list-wrap {
          display: flex;
          flex-wrap: wrap;
          .live-item {
            display: flex;
            flex-direction: column;
            margin: 20px 17.5px 0 0;
            width: 375px;
            height: 155px;
            background: #F8F9FB;
            border-radius: 6px;
            padding: 20px 17px 0 19px;
            line-height: 1;
            cursor: pointer;
            .title-wrap {
              display: flex;
              align-items: center;
              margin-bottom: 16px;
              .free-state {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 40px;
                height: 22px;
                background: #FB8A00;
                border-radius: 4px;
                margin-right: 10px;
                font-size: 14px;
                color: #FFFFFF;
              }
              .live-title {
                flex: 1;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-size: 18px;
                color: #222222;
              }
            }
            .live-date {
              font-size: 16px;
              font-weight: 400;
              color: #333333;
              margin-bottom: 20px;
            }
            .live-info{
              display: flex;
              justify-content: space-between;
              align-items: center;
              .live-teacher {
                width: 38px;
                height: 38px;
                border-radius: 50%;
              }
              .live-state {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 25px;
                width: 80px;
                height: 24px;
                border-radius: 12px;
                img {
                  margin-right: 6px;
                }
                span {
                  font-size: 14px;
                  color: #FFFFFF;
                }
              }
              .live-state-start {
                background: #2869F5;
                img {
                  width: 14px;
                  height: 15px;
                }
              }
              .live-state-before {
                background: #7438EF;
                img {
                  width: 16px;
                  height: 16px;
                }
              }
              .live-state-end {
                background: #CFCFCF;
                img {
                  width: 16px;
                  height: 16px;
                }
              }
              .right {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                min-height: 100%;
                flex: 1;
                align-items: flex-end;
                .price {
                  font-size: 18px;
                  font-weight: bold;
                  color: #E22323;
                  margin-bottom: 15px;
                }
                .count {
                  font-size: 16px;
                  font-weight: 400;
                  color: #666666;
                }
              }
            }
          }
          .live-item:nth-child(3n) {
            margin-right: 0;
          }
        }
        .course-no-data {
          display: flex;
          flex-direction: column;
          align-items: center;
          img {
            width: 298px;
            height: 207px;
            margin-top: 90px;
            margin-bottom: 27px;
          }
          span {
            font-size: 16px;
            font-weight: 400;
            color: #666666;
            line-height: 1;
          }
          div {
            width: 160px;
            height: 40px;
            background: #2869F5;
            border-radius: 20px;
            margin-top: 34px;
            text-align: center;
            line-height: 40px;
            font-size: 18px;
            color: #FFFFFF;
            margin-bottom: 47px;
            cursor: pointer;
          }
        }
        .live-no-data {
          display: flex;
          flex-direction: column;
          align-items: center;
          img {
            width: 328px;
            height: 229px;
            margin-bottom: 16px;
            margin-top: 43px;
          }
          span {
            font-size: 16px;
            font-weight: 400;
            color: #666666;
            margin-bottom: 40px;
          }
        }
        .live-blank {
          padding-bottom: 21px;
        }
      }
    }
}
</style>
